import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { mq } from '../utils/helpers';

import {
  Footer,
  FlatBoxes,
  Layout,
  Boxes,
  HomeText,
} from '../containers';
import { Container, HomeTextContainer } from '../styled';
import { ImageOverlayed } from '../components';
import { BoxesContainer } from '../containers/FlatBoxes';

class PortfolioPageTemplate extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  myRef= null;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
      let mobileView = (window.innerWidth <= 760);
      if (mobileView && (this.props.pageContext.contener=='portfolioMovies' || this.props.pageContext.contener=='portfolioDigital' || this.props.pageContext.contener=='portfolioDesign')) {
          window.scroll({ left: 0, top: this.myRef.offsetTop - 150, behavior: 'smooth'});
      }
  }

    getBoxes() {
    const { data } = this.props;
    return data.allMarkdownRemark.edges.filter(el =>  el.node.frontmatter.contener == this.props.pageContext.contener)[0].node.frontmatter;
  }

    getData(key) {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el => el.node.frontmatter[key])[0].node.frontmatter;
    }

  render() {
    const { data } = this.props;
    const { portfolioboxes } = this.getBoxes();
    const { contact } = this.getData('contact');

    return (
      <Layout renderWithChat>
        <FlatBoxes data={data} movies={this.props.pageContext.contener=='portfolioMovies'} digital={this.props.pageContext.contener=='portfolioDigital'} design={this.props.pageContext.contener=='portfolioDesign'}/>



          <HomeText data={data} marginTop={0}>
              {this.props.pageContext.contener=='portfolioMovies'?<div ref={ (ref) => this.myRef=ref }>
                  <p>Мы являемся видеоагентством, поэтому производство фильмов - это наша специальность. У нас есть все необходимое для производства и постпродакшина различных видеоформатов. Мы осуществляем творческий контроль над сценарием, а также предлагаем услуги профессионального копирайтинга. В студии мы обеспечиваем эффективное сотрудничество колориста, режиссера и оператора.</p>
                  <p>В нашем портфолио Вы найдете анимационные ролики, короткие и длинные рекламные видеоролики, телевизионные ролики, спонсорские, рекламные, имиджевые, корпоративные и обучающие билборды для крупного и малого бизнеса. Мы придумываем и снимаем все от начала до конца, принимая во внимание видение Вашего бренда, бюджет и&nbsp;потребности. Мы реализуем прямые эфиры событий в сети - трансляции конференций, тренингов, концертов, вебкастов, вебинаров - от регистрации до постпродакшина вместе с полным графическим оформлением трансляции.</p>
              </div>:this.props.pageContext.contener=='portfolioDigital'?
              <div ref={ (ref) => this.myRef=ref }>
                      <p>Мы разрабатываем цифровые стратегии, чтобы показать Ваш продукт в лучшем виде. Наши органические и рекламные действия помогают расширить охват, увеличить продажи и создать целостный имидж бренда.</p>
                      <p>Мы работаем в социальных сетях Facebook, Instagram и YouTube. Мы создаем проекты контента в социальных сетях, баннерную рекламу и предоставляем комплексные графические услуги электронной коммерции. Мы ориентируемся на&nbsp;создание последовательной визуальной коммуникации постов и рекламы, мы адаптируем глобальные материалы так, чтобы они привлекали внимание Ваших идеальных клиентов.</p>
                      <p>Прежде чем начать сотрудничество, мы проведем тщательный анализ бренда, обсудим Ваши бизнес-цели и согласуем бюджет. Никакая цель, маленькая или большая, нас не пугает!</p>
              </div>:this.props.pageContext.contener=='portfolioDesign'?<div ref={ (ref) => this.myRef=ref }>
                      <p>Мы&nbsp;создаем все маркетинговые графические формы, идеально подходящие для Вашей фирмы, как в Интернете, так&nbsp;и&nbsp;для печати. </p>
                      <p>После обсуждения миссии и цели, мы адаптируем работу к потребностям Вашей ниши. Мы можем разработать брендинг с нуля, создать адаптивный веб-сайт, логотипы, кейвижуалы, а также статическую и анимированную графику. Также мы создаем проекты брошюр, каталогов и другой печатной продукции. </p>
                      <p>Мы предлагаем удобные и гибкие условия комплексного обслуживания - Вы можете выбрать проекты, идеально соответствующие размеру компании, бюджету и потребностям.</p>
                  </div>:<div></div>}
          </HomeText>

          <HomeTextContainer>
              <Container>
                  <PortfolioHeader>Наши проекты</PortfolioHeader>
              </Container>
          </HomeTextContainer>

        <Boxes data={portfolioboxes} />

        <HomeText data={data} marginTop={2}>
        </HomeText>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default PortfolioPageTemplate;

const PortfolioHeader = styled.h2`
  font-weight: 600;
  font-size: 30;
  font-family: 'Galano Grotesque Light','Helvetica','Segoe UI','Helvetica Neue','Arial',sans-serif;
  font-weight: normal;
`;

export const query = graphql`
  query {
    filmy: file(relativePath: { eq: "filmy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    digital: file(relativePath: { eq: "digital.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design: file(relativePath: { eq: "design.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {  
            contener
            portfolioboxes {
              row {
                box {
                  title
                  destination
                  image_url {
                  childImageSharp {
                      fluid (maxWidth: 3080, quality: 100) { 
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  
                  hover_url {
                    publicURL
                  }
                  
                 }
              }
            }
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
